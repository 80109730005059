import { default as _91slug_93Ah4g7yEXTRMeta } from "/home/ubuntu/deployments/2025-04-04-1404/rtg-monorepo/src/layers/web/pages/tourleader/[id]/[slug].vue?macro=true";
import { default as _91slug_93UzLUHUq3QaMeta } from "/home/ubuntu/deployments/2025-04-04-1404/rtg-monorepo/src/layers/web/pages/titinerary/[slug].vue?macro=true";
import { default as _91name_9306OarsGjWAMeta } from "/home/ubuntu/deployments/2025-04-04-1404/rtg-monorepo/src/layers/web/pages/shipinfo/[name].vue?macro=true";
import { default as _91id_93mZWnHdBq4zMeta } from "/home/ubuntu/deployments/2025-04-04-1404/rtg-monorepo/src/layers/web/pages/payment/[id].vue?macro=true";
import { default as _91id_9328WpwDBRIfMeta } from "/home/ubuntu/deployments/2025-04-04-1404/rtg-monorepo/src/layers/web/pages/omdome/[id].vue?macro=true";
import { default as _91tab_936TmTm1mOCnMeta } from "/home/ubuntu/deployments/2025-04-04-1404/rtg-monorepo/src/layers/web/pages/mytrip/[tab].vue?macro=true";
import { default as _91slug_93QArnrmLK2FMeta } from "/home/ubuntu/deployments/2025-04-04-1404/rtg-monorepo/src/layers/web/pages/info/[slug].vue?macro=true";
import { default as _91tab_93f6gE18GiapMeta } from "/home/ubuntu/deployments/2025-04-04-1404/rtg-monorepo/src/applications/solresor-web/pages/hotel/[id]/[tab].vue?macro=true";
import { default as _91id_93k0f6Xpvh6GMeta } from "/home/ubuntu/deployments/2025-04-04-1404/rtg-monorepo/src/layers/web/pages/fn/[id].vue?macro=true";
import { default as _91tab_93a0BLKhYCKKMeta } from "/home/ubuntu/deployments/2025-04-04-1404/rtg-monorepo/src/applications/solresor-web/pages/destination/[id]/[tab].vue?macro=true";
import { default as _91slug_933wYwx3ug9PMeta } from "/home/ubuntu/deployments/2025-04-04-1404/rtg-monorepo/src/layers/web/pages/competition/[slug].vue?macro=true";
import { default as _91name_93mL49s2B0HfMeta } from "/home/ubuntu/deployments/2025-04-04-1404/rtg-monorepo/src/applications/solresor-web/pages/campaign/[name].vue?macro=true";
import { default as _91ocr_935wiS11UzKDMeta } from "/home/ubuntu/deployments/2025-04-04-1404/rtg-monorepo/src/layers/web/pages/bookingfaq/[ocr].vue?macro=true";
import { default as _91ocr_93a93ogKt9R3Meta } from "/home/ubuntu/deployments/2025-04-04-1404/rtg-monorepo/src/layers/web/pages/booking-finish/[ocr].vue?macro=true";
import { default as _91id_93T169HHzRZvMeta } from "/home/ubuntu/deployments/2025-04-04-1404/rtg-monorepo/src/layers/web/pages/booking-confirmation/[id].vue?macro=true";
import { default as _91step_93z1wtdTMGuEMeta } from "/home/ubuntu/deployments/2025-04-04-1404/rtg-monorepo/src/layers/web/pages/book/[tripid]/[step].vue?macro=true";
import { default as _91jwt_93NU8EWanVNbMeta } from "/home/ubuntu/deployments/2025-04-04-1404/rtg-monorepo/src/layers/web/pages/app/l/[jwt].vue?macro=true";
import { default as _91tab_932C9gJj5lhvMeta } from "/home/ubuntu/deployments/2025-04-04-1404/rtg-monorepo/src/layers/web/pages/[triptype]/[slug]/[tab].vue?macro=true";
import { default as indexKRxPt5yWlVMeta } from "/home/ubuntu/deployments/2025-04-04-1404/rtg-monorepo/src/layers/web/pages/app/index.vue?macro=true";
import { default as byebyeieK4NlQrRsCqMeta } from "/home/ubuntu/deployments/2025-04-04-1404/rtg-monorepo/src/layers/web/pages/byebyeie.vue?macro=true";
import { default as gruppresorlQf2MbpyA1Meta } from "/home/ubuntu/deployments/2025-04-04-1404/rtg-monorepo/src/applications/solresor-web/pages/campaign/gruppresor.vue?macro=true";
import { default as chartersearchbQykZdCFC5Meta } from "/home/ubuntu/deployments/2025-04-04-1404/rtg-monorepo/src/applications/solresor-web/pages/chartersearch.vue?macro=true";
import { default as complaintKbij1CNNiHMeta } from "/home/ubuntu/deployments/2025-04-04-1404/rtg-monorepo/src/layers/web/pages/complaint.vue?macro=true";
import { default as indexZW8BrZxKOzMeta } from "/home/ubuntu/deployments/2025-04-04-1404/rtg-monorepo/src/applications/solresor-web/pages/destination/index.vue?macro=true";
import { default as dinbokning_45aktuellNWg5V4dbR2Meta } from "/home/ubuntu/deployments/2025-04-04-1404/rtg-monorepo/src/layers/web/pages/dinbokning-aktuell.vue?macro=true";
import { default as faqh9mDNjHDxSMeta } from "/home/ubuntu/deployments/2025-04-04-1404/rtg-monorepo/src/layers/web/pages/faq.vue?macro=true";
import { default as indexlvybH9fXqvMeta } from "/home/ubuntu/deployments/2025-04-04-1404/rtg-monorepo/src/applications/solresor-web/pages/favourites/index.vue?macro=true";
import { default as _91id_937y9OLYEMplMeta } from "/home/ubuntu/deployments/2025-04-04-1404/rtg-monorepo/src/layers/web/pages/giftcard/[id].vue?macro=true";
import { default as indexQfNq9hQ2XlMeta } from "/home/ubuntu/deployments/2025-04-04-1404/rtg-monorepo/src/layers/web/pages/giftcard/index.vue?macro=true";
import { default as indexJqKwyMV3YzMeta } from "/home/ubuntu/deployments/2025-04-04-1404/rtg-monorepo/src/applications/solresor-web/pages/hotel/index.vue?macro=true";
import { default as _91name_93jHw5CF8vxNMeta } from "/home/ubuntu/deployments/2025-04-04-1404/rtg-monorepo/src/layers/web/pages/hotels/[name].vue?macro=true";
import { default as index1meXMnk6A2Meta } from "/home/ubuntu/deployments/2025-04-04-1404/rtg-monorepo/src/layers/web/pages/hotels/index.vue?macro=true";
import { default as indexA5QVDaLF84Meta } from "/home/ubuntu/deployments/2025-04-04-1404/rtg-monorepo/src/applications/solresor-web/pages/index.vue?macro=true";
import { default as dinbokningrzQLLDDHE4Meta } from "/home/ubuntu/deployments/2025-04-04-1404/rtg-monorepo/src/layers/web/pages/mytrip/dinbokning.vue?macro=true";
import { default as newsletterwi1YmU6kz7Meta } from "/home/ubuntu/deployments/2025-04-04-1404/rtg-monorepo/src/layers/web/pages/newsletter.vue?macro=true";
import { default as offlinexPxYsm3Kf8Meta } from "/home/ubuntu/deployments/2025-04-04-1404/rtg-monorepo/src/layers/web/pages/offline.vue?macro=true";
import { default as packagessearchnPiiAbKdPuMeta } from "/home/ubuntu/deployments/2025-04-04-1404/rtg-monorepo/src/applications/solresor-web/pages/packagessearch.vue?macro=true";
import { default as failed4ithaJOr70Meta } from "/home/ubuntu/deployments/2025-04-04-1404/rtg-monorepo/src/layers/web/pages/payment/failed.vue?macro=true";
import { default as indexT6cAzb9qxLMeta } from "/home/ubuntu/deployments/2025-04-04-1404/rtg-monorepo/src/layers/web/pages/review/index.vue?macro=true";
import { default as search8TOi6FO3OgMeta } from "/home/ubuntu/deployments/2025-04-04-1404/rtg-monorepo/src/layers/web/pages/search.vue?macro=true";
import { default as indexTIY4ZpZ8O4Meta } from "/home/ubuntu/deployments/2025-04-04-1404/rtg-monorepo/src/layers/web/pages/tourleader/index.vue?macro=true";
import { default as verification_45doneexOYVPH91cMeta } from "/home/ubuntu/deployments/2025-04-04-1404/rtg-monorepo/src/layers/web/pages/verification-done.vue?macro=true";
export default [
  {
    name: "tourleader-id",
    path: "/reseledare/:id()",
    component: () => import("/home/ubuntu/deployments/2025-04-04-1404/rtg-monorepo/src/layers/web/pages/tourleader/[id]/[slug].vue")
  },
  {
    name: "titinerary",
    path: "/dagprogram",
    component: () => import("/home/ubuntu/deployments/2025-04-04-1404/rtg-monorepo/src/layers/web/pages/titinerary/[slug].vue")
  },
  {
    name: "shipinfo",
    path: "/fartyg",
    component: () => import("/home/ubuntu/deployments/2025-04-04-1404/rtg-monorepo/src/layers/web/pages/shipinfo/[name].vue")
  },
  {
    name: "payment",
    path: "/payment",
    component: () => import("/home/ubuntu/deployments/2025-04-04-1404/rtg-monorepo/src/layers/web/pages/payment/[id].vue")
  },
  {
    name: "omdome",
    path: "/omdome",
    component: () => import("/home/ubuntu/deployments/2025-04-04-1404/rtg-monorepo/src/layers/web/pages/omdome/[id].vue")
  },
  {
    name: "mytrip",
    path: "/dinresa",
    component: () => import("/home/ubuntu/deployments/2025-04-04-1404/rtg-monorepo/src/layers/web/pages/mytrip/[tab].vue")
  },
  {
    name: "info",
    path: "/info",
    component: () => import("/home/ubuntu/deployments/2025-04-04-1404/rtg-monorepo/src/layers/web/pages/info/[slug].vue")
  },
  {
    name: "hotel-id",
    path: "/hotel/:id()",
    component: () => import("/home/ubuntu/deployments/2025-04-04-1404/rtg-monorepo/src/applications/solresor-web/pages/hotel/[id]/[tab].vue")
  },
  {
    name: "fn",
    path: "/fn",
    component: () => import("/home/ubuntu/deployments/2025-04-04-1404/rtg-monorepo/src/layers/web/pages/fn/[id].vue")
  },
  {
    name: "destination-id",
    path: "/destination/:id()",
    component: () => import("/home/ubuntu/deployments/2025-04-04-1404/rtg-monorepo/src/applications/solresor-web/pages/destination/[id]/[tab].vue")
  },
  {
    name: "competition",
    path: "/tavling",
    component: () => import("/home/ubuntu/deployments/2025-04-04-1404/rtg-monorepo/src/layers/web/pages/competition/[slug].vue")
  },
  {
    name: "campaign",
    path: "/campaign",
    component: () => import("/home/ubuntu/deployments/2025-04-04-1404/rtg-monorepo/src/applications/solresor-web/pages/campaign/[name].vue")
  },
  {
    name: "bookingfaq",
    path: "/boknings-faq",
    component: () => import("/home/ubuntu/deployments/2025-04-04-1404/rtg-monorepo/src/layers/web/pages/bookingfaq/[ocr].vue")
  },
  {
    name: "booking-finish",
    path: "/booking-finish",
    component: () => import("/home/ubuntu/deployments/2025-04-04-1404/rtg-monorepo/src/layers/web/pages/booking-finish/[ocr].vue")
  },
  {
    name: "booking-confirmation",
    path: "/booking-confirmation",
    component: () => import("/home/ubuntu/deployments/2025-04-04-1404/rtg-monorepo/src/layers/web/pages/booking-confirmation/[id].vue")
  },
  {
    name: "book-tripid",
    path: "/boka/:tripid()",
    component: () => import("/home/ubuntu/deployments/2025-04-04-1404/rtg-monorepo/src/layers/web/pages/book/[tripid]/[step].vue")
  },
  {
    name: "app-l",
    path: "/app/l",
    component: () => import("/home/ubuntu/deployments/2025-04-04-1404/rtg-monorepo/src/layers/web/pages/app/l/[jwt].vue")
  },
  {
    name: "triptype-slug",
    path: "/:triptype()/:slug()",
    component: () => import("/home/ubuntu/deployments/2025-04-04-1404/rtg-monorepo/src/layers/web/pages/[triptype]/[slug]/[tab].vue")
  },
  {
    name: "triptype-slug-tab",
    path: "/:triptype()/:slug()/:tab()",
    meta: _91tab_932C9gJj5lhvMeta || {},
    component: () => import("/home/ubuntu/deployments/2025-04-04-1404/rtg-monorepo/src/layers/web/pages/[triptype]/[slug]/[tab].vue")
  },
  {
    name: "app",
    path: "/app",
    component: () => import("/home/ubuntu/deployments/2025-04-04-1404/rtg-monorepo/src/layers/web/pages/app/index.vue")
  },
  {
    name: "app-l-jwt",
    path: "/app/l/:jwt()",
    component: () => import("/home/ubuntu/deployments/2025-04-04-1404/rtg-monorepo/src/layers/web/pages/app/l/[jwt].vue")
  },
  {
    name: "book-tripid-step",
    path: "/boka/:tripid()/:step()",
    component: () => import("/home/ubuntu/deployments/2025-04-04-1404/rtg-monorepo/src/layers/web/pages/book/[tripid]/[step].vue")
  },
  {
    name: "booking-confirmation-id",
    path: "/booking-confirmation/:id()",
    component: () => import("/home/ubuntu/deployments/2025-04-04-1404/rtg-monorepo/src/layers/web/pages/booking-confirmation/[id].vue")
  },
  {
    name: "booking-finish-ocr",
    path: "/booking-finish/:ocr()",
    component: () => import("/home/ubuntu/deployments/2025-04-04-1404/rtg-monorepo/src/layers/web/pages/booking-finish/[ocr].vue")
  },
  {
    name: "bookingfaq-ocr",
    path: "/boknings-faq/:ocr()",
    component: () => import("/home/ubuntu/deployments/2025-04-04-1404/rtg-monorepo/src/layers/web/pages/bookingfaq/[ocr].vue")
  },
  {
    name: "byebyeie",
    path: "/byebyeie",
    component: () => import("/home/ubuntu/deployments/2025-04-04-1404/rtg-monorepo/src/layers/web/pages/byebyeie.vue")
  },
  {
    name: "campaign-name",
    path: "/campaign/:name()",
    meta: _91name_93mL49s2B0HfMeta || {},
    component: () => import("/home/ubuntu/deployments/2025-04-04-1404/rtg-monorepo/src/applications/solresor-web/pages/campaign/[name].vue")
  },
  {
    name: "campaign-gruppresor",
    path: "/campaign/gruppresor",
    component: () => import("/home/ubuntu/deployments/2025-04-04-1404/rtg-monorepo/src/applications/solresor-web/pages/campaign/gruppresor.vue")
  },
  {
    name: "chartersearch",
    path: "/sok-charter",
    component: () => import("/home/ubuntu/deployments/2025-04-04-1404/rtg-monorepo/src/applications/solresor-web/pages/chartersearch.vue")
  },
  {
    name: "competition-slug",
    path: "/tavling/:slug()",
    component: () => import("/home/ubuntu/deployments/2025-04-04-1404/rtg-monorepo/src/layers/web/pages/competition/[slug].vue")
  },
  {
    name: "complaint",
    path: "/reklamation",
    meta: complaintKbij1CNNiHMeta || {},
    component: () => import("/home/ubuntu/deployments/2025-04-04-1404/rtg-monorepo/src/layers/web/pages/complaint.vue")
  },
  {
    name: "destination-id-tab",
    path: "/destination/:id()/:tab()",
    component: () => import("/home/ubuntu/deployments/2025-04-04-1404/rtg-monorepo/src/applications/solresor-web/pages/destination/[id]/[tab].vue")
  },
  {
    name: "destination",
    path: "/destination",
    component: () => import("/home/ubuntu/deployments/2025-04-04-1404/rtg-monorepo/src/applications/solresor-web/pages/destination/index.vue")
  },
  {
    name: "dinbokning-aktuell",
    path: "/dinbokning-aktuell",
    component: () => import("/home/ubuntu/deployments/2025-04-04-1404/rtg-monorepo/src/layers/web/pages/dinbokning-aktuell.vue")
  },
  {
    name: "faq",
    path: "/faq",
    component: () => import("/home/ubuntu/deployments/2025-04-04-1404/rtg-monorepo/src/layers/web/pages/faq.vue")
  },
  {
    name: "favourites",
    path: "/favourites",
    component: () => import("/home/ubuntu/deployments/2025-04-04-1404/rtg-monorepo/src/applications/solresor-web/pages/favourites/index.vue")
  },
  {
    name: "fn-id",
    path: "/fn/:id()",
    component: () => import("/home/ubuntu/deployments/2025-04-04-1404/rtg-monorepo/src/layers/web/pages/fn/[id].vue")
  },
  {
    name: "giftcard-id",
    path: "/presentkort/:id()",
    component: () => import("/home/ubuntu/deployments/2025-04-04-1404/rtg-monorepo/src/layers/web/pages/giftcard/[id].vue")
  },
  {
    name: "giftcard",
    path: "/presentkort",
    component: () => import("/home/ubuntu/deployments/2025-04-04-1404/rtg-monorepo/src/layers/web/pages/giftcard/index.vue")
  },
  {
    name: "hotel-id-tab",
    path: "/hotel/:id()/:tab()",
    component: () => import("/home/ubuntu/deployments/2025-04-04-1404/rtg-monorepo/src/applications/solresor-web/pages/hotel/[id]/[tab].vue")
  },
  {
    name: "hotel",
    path: "/hotel",
    component: () => import("/home/ubuntu/deployments/2025-04-04-1404/rtg-monorepo/src/applications/solresor-web/pages/hotel/index.vue")
  },
  {
    name: "hotels-name",
    path: "/hotell/:name()",
    meta: _91name_93jHw5CF8vxNMeta || {},
    component: () => import("/home/ubuntu/deployments/2025-04-04-1404/rtg-monorepo/src/layers/web/pages/hotels/[name].vue")
  },
  {
    name: "hotels",
    path: "/hotell",
    component: () => import("/home/ubuntu/deployments/2025-04-04-1404/rtg-monorepo/src/layers/web/pages/hotels/index.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/home/ubuntu/deployments/2025-04-04-1404/rtg-monorepo/src/applications/solresor-web/pages/index.vue")
  },
  {
    name: "info-slug",
    path: "/info/:slug()",
    meta: _91slug_93QArnrmLK2FMeta || {},
    component: () => import("/home/ubuntu/deployments/2025-04-04-1404/rtg-monorepo/src/layers/web/pages/info/[slug].vue")
  },
  {
    name: "mytrip-tab",
    path: "/dinresa/:tab()",
    meta: _91tab_936TmTm1mOCnMeta || {},
    component: () => import("/home/ubuntu/deployments/2025-04-04-1404/rtg-monorepo/src/layers/web/pages/mytrip/[tab].vue")
  },
  {
    name: "mytrip-dinbokning",
    path: "/mytrip/dinbokning",
    component: () => import("/home/ubuntu/deployments/2025-04-04-1404/rtg-monorepo/src/layers/web/pages/mytrip/dinbokning.vue")
  },
  {
    name: "newsletter",
    path: "/nyhetsbrev",
    component: () => import("/home/ubuntu/deployments/2025-04-04-1404/rtg-monorepo/src/layers/web/pages/newsletter.vue")
  },
  {
    name: "offline",
    path: "/offline",
    component: () => import("/home/ubuntu/deployments/2025-04-04-1404/rtg-monorepo/src/layers/web/pages/offline.vue")
  },
  {
    name: "omdome-id",
    path: "/omdome/:id()",
    meta: _91id_9328WpwDBRIfMeta || {},
    component: () => import("/home/ubuntu/deployments/2025-04-04-1404/rtg-monorepo/src/layers/web/pages/omdome/[id].vue")
  },
  {
    name: "packagessearch",
    path: "/sok-packages",
    component: () => import("/home/ubuntu/deployments/2025-04-04-1404/rtg-monorepo/src/applications/solresor-web/pages/packagessearch.vue")
  },
  {
    name: "payment-id",
    path: "/payment/:id()",
    component: () => import("/home/ubuntu/deployments/2025-04-04-1404/rtg-monorepo/src/layers/web/pages/payment/[id].vue")
  },
  {
    name: "payment-failed",
    path: "/payment/failed",
    component: () => import("/home/ubuntu/deployments/2025-04-04-1404/rtg-monorepo/src/layers/web/pages/payment/failed.vue")
  },
  {
    name: "review",
    path: "/utvardera-resa",
    component: () => import("/home/ubuntu/deployments/2025-04-04-1404/rtg-monorepo/src/layers/web/pages/review/index.vue")
  },
  {
    name: "search",
    path: "/sok",
    component: () => import("/home/ubuntu/deployments/2025-04-04-1404/rtg-monorepo/src/layers/web/pages/search.vue")
  },
  {
    name: "shipinfo-name",
    path: "/fartyg/:name()",
    meta: _91name_9306OarsGjWAMeta || {},
    component: () => import("/home/ubuntu/deployments/2025-04-04-1404/rtg-monorepo/src/layers/web/pages/shipinfo/[name].vue")
  },
  {
    name: "titinerary-slug",
    path: "/dagprogram/:slug()",
    component: () => import("/home/ubuntu/deployments/2025-04-04-1404/rtg-monorepo/src/layers/web/pages/titinerary/[slug].vue")
  },
  {
    name: "tourleader-id-slug",
    path: "/reseledare/:id()/:slug()",
    component: () => import("/home/ubuntu/deployments/2025-04-04-1404/rtg-monorepo/src/layers/web/pages/tourleader/[id]/[slug].vue")
  },
  {
    name: "tourleader",
    path: "/reseledare",
    component: () => import("/home/ubuntu/deployments/2025-04-04-1404/rtg-monorepo/src/layers/web/pages/tourleader/index.vue")
  },
  {
    name: "verification-done",
    path: "/verification-done",
    meta: verification_45doneexOYVPH91cMeta || {},
    component: () => import("/home/ubuntu/deployments/2025-04-04-1404/rtg-monorepo/src/layers/web/pages/verification-done.vue")
  }
]